import './Team.css'
import team1 from '../../assets/Team/team1.jpg'
import team3 from '../../assets/Team/team3.png'
import team4 from '../../assets/Team/team4.png'
import team5 from '../../assets/Team/team5.png'
import team6 from '../../assets/Team/team6.png'
import team7 from '../../assets/Team/team7.png'

const Team = () => {
    return (
        <div className="team">
            <div className="logo">
                <h2>Team</h2>
            </div>
            <div className='team-images'>
                <div>
                    <img src={team1} height={380} width={500} alt="" />
                    <h4 style={{ textAlign: "start" }}>Our Team</h4>
                </div>
                <div>
                    <img src={team3} height={380} width={380} alt="" />
                    <h4 style={{ textAlign: "start" }}>CEO & FOUNDER</h4>
                    <p style={{ textAlign: "start" }}>Naga Venkata</p>
                </div>
            </div>
            <br />
            <br />
            <div className='images'>
                <div>
                    <img src={team5} height={230} width={230} alt="" />
                    <h4 style={{ textAlign: "start" }}>Delivery Manager</h4>
                    <p style={{ textAlign: "start" }}>Sudhakar</p>
                </div>
                <div>
                    <img src={team6} height={230} width={230} alt="" />
                    <h4 style={{ textAlign: "start" }}>Team Lead</h4>
                    <p style={{ textAlign: "start" }}>Venkata Chary</p>
                </div>
                <div>
                    <img src={team4} height={230} width={230} alt="" />
                    <h4 style={{ textAlign: "start" }}>Senior Developer</h4>
                    <p style={{ textAlign: "start" }}>Krishna</p>
                </div>
                <div>
                    <img src={team7} height={230} width={230} alt="" />
                    <h4 style={{ textAlign: "start" }}>Ux Designer</h4>
                    <p style={{ textAlign: "start" }}>Hussain</p>
                </div>
            </div>
        </div>
    )
}
export default Team;