import MobileApp1 from '../../../assets/SliderImages/MobileApp1.jpg'
import MobileApp2 from '../../../assets/SliderImages/MobileApp2.jpg'
import MobileApp4 from '../../../assets/SliderImages/MobileApp4.png'
import UxDesign from '../../../assets/SliderImages/UxDesign.jpg'
import SliderTeam from '../../../assets/SliderImages/SliderTeam.jpg'

const SliderImage = [

    {
        urls: SliderTeam
    },
    {
        urls: MobileApp1
    },
    {
        urls: MobileApp2
    },
    {
        urls: MobileApp4
    },
    {
        urls: UxDesign
    }
]
export default SliderImage;