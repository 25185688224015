import UxDesign from '../../assets/SliderImages/UxDesign.jpg'
import MobileApp from '../../assets/SliderImages/MobileApp1.jpg'
import AwsCloud from '../../assets/services/AwsCloud.jpg'
import AzureCloud from '../../assets/services/AzureCloud.png'
import DevOps from '../../assets/services/DevOps.png'
import WebDevelopment from '../../assets/services/WebDevelopment.jpg'
import PythonDevelopment from '../../assets/services/PythonDevelopment.jpg'
import PowerBI from '../../assets/services/PowerBI.jpg'

import './Services.css'

const Services = () => {
    return (
        <div className='service'>
            <div className="logo">
                <h2>Our Services</h2>
            </div>
            <div className='service-card'>
                <div className="skill-card">
                    <div>
                        <header className="skill-card__header">
                            <img className="skill-card__icon" src={WebDevelopment} alt="" />
                        </header>
                        <h2 className="skill-card__title">Web Development</h2>
                        <section className="skill-card__body">
                            <p>Digital world has growinng faster day by day and semicolon is one of the them to grow it.</p>
                        </section>
                    </div>
                </div>
                <div className="skill-card">
                    <div>
                        <header className="skill-card__header">
                            <img className="skill-card__icon" src={MobileApp} alt="" />
                        </header>
                        <h2 className="skill-card__title">Mobile Development</h2>
                        <section className="skill-card__body">
                            <p>Mdits works on latest technology such as Flutter and React and Flutter.</p>
                        </section>
                    </div>
                </div>
                <div className="skill-card">
                    <div>
                        <header className="skill-card__header">
                            <img className="skill-card__icon" src={UxDesign} alt="" />
                        </header>
                        <h2 className="skill-card__title">UI/UX Designing</h2>
                        <section className="skill-card__body">
                            <p>Adobe XD, Sketch, Invision,Photoshop,Illustrator, Figma UI&#x2F;UX Makes your application alive to see how it's look.</p>
                        </section>
                    </div>
                </div>
                <div className="skill-card">
                    <div>
                        <header className="skill-card__header">
                            <img className="skill-card__icon" src={DevOps} alt="" />
                        </header>
                        <h2 className="skill-card__title">Devops</h2>
                        <section className="skill-card__body">
                            <p>Devops</p>
                        </section>
                    </div>
                </div>
            </div>
            <div style={{paddingTop:'10px'}}>
                <div className='service-card'>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={PythonDevelopment} alt="" />
                            </header>
                            <h2 className="skill-card__title">Python Development</h2>
                            <section className="skill-card__body">
                                <p>Python Development</p>
                            </section>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={PowerBI} alt="" />
                            </header>
                            <h2 className="skill-card__title">Power BI</h2>
                            <section className="skill-card__body">
                                <p>Power BI</p>
                            </section>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={AwsCloud} alt="" />
                            </header>
                            <h2 className="skill-card__title">AWS Cloud</h2>
                            <section className="skill-card__body">
                                <p>Azure Cloud</p>
                            </section>
                        </div>
                    </div>
                    <div className="skill-card">
                        <div>
                            <header className="skill-card__header">
                                <img className="skill-card__icon" src={AzureCloud} alt="" />
                            </header>
                            <h2 className="skill-card__title">Azure Cloud</h2>
                            <section className="skill-card__body">
                                <p>Azure Cloud</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Services;