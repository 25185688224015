import OurMission from '../../assets/Team/OurMission.jpg'
import Team1 from '../../assets/Team/team1.jpg'
import './About.css'

const About = () => {
    return (
        <div className='about-us'>
            <div className="logo">
                <h2>About Us</h2>
            </div>
            <div className='about'>
                <div className="about-container">
                    <div>
                        <p>
                            We MDITS [Mission Driven IT Solutions Private Limited] build products with high quality and  follow certain business and marketing strategies to connect with customers worldwide and bring more business to our clients.
                        </p>
                        <p style={{ paddingTop: '7px' }}>
                            We're driven by our commitment to deliver exceptional products, services and experiences to our customers. We value our strong customer relationships, and are defined by how well we take care of them.
                        </p>
                        <p style={{ paddingTop: '7px' }}>As a leader in technology exploring, MDITS is committed to exporting quality software worldwide.</p>
                        <p style={{ paddingTop: '7px' }}>Our key market advantage is the ability to leverage a wealth of experience in this sector, a network of local and international partners, and a very competitive pricing strategy to deliver quality software solutions.</p>
                    </div>
                    <img className='about-team-image1' src={Team1} alt="" />
                </div>
            </div>
            <div className='Our-mission'>
                <img width={820} src={OurMission} alt="" />
            </div>

        </div>
    )
}
export default About;