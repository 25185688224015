import './Portfolio.css'
import Web from '../../assets/portfolio/Web.gif'
import Mobile from '../../assets/portfolio/Mobile.gif'
import UxDesign from '../../assets/portfolio/UxDesign.gif'

const Portfolio = () => {
    return (
        <div className='portfolio'>
            <div className="logo">
                <h2>Portfolio</h2>
            </div>
            <div className='cards'>
                <div className="porfolio-container">
                    <h2 className='portfolio-title'>Web Development</h2>
                    <br />
                    <p>
                        Well organized and easy to understand
                        Web building tutorials with lots of examples of how to use HTML,
                        CSS, JavaScript, SQL, Python, PHP, Bootstrap, Java, ...
                    </p>
                </div>
                <img className='web-animation' src={Web} alt="" />
            </div>
            <div className='cards'>
                <img className='mobile-animation' src={Mobile} alt="" />
                <div className="porfolio-mobile-container">
                    <h2 className='portfolio-title'>Mobile Development</h2>
                    <br />
                    <p>
                        A mobile application or app is a computer program
                        or software application designed to run on a mobile
                        device such as a phone, tablet or watch.
                    </p>
                </div>
            </div>
            <div className='cards'>
                <div className="porfolio-container">
                    <h2 className='portfolio-title'>Ux/Ui Designing</h2>
                    <br />
                    <p>
                        The Devops/Cloud Specialization brings a design-centric
                        approach to user interface and user experience design,
                        and offers practical, skill-based instruction ...
                    </p>
                </div>
                <img className='web-animation' src={UxDesign} alt="" />
            </div> 
        </div>
    )
}
export default Portfolio;