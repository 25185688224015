import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer'
import Home from './Pages/Home/Home'
import About from './Pages/About/About'
import Contact from './Pages/Contact/Contact'
import Team from './Pages/Team/Team'
import Services from './Pages/Services/Services'
import Portfolio from './Pages/Portfolio/Portfolio'

function App() {
  return (
    <div >
      <Router>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/services' element={<Services />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/team' element={<Team />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
