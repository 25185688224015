import { Link } from 'react-router-dom'
import './Navbar.css'
import MDITS from '../../assets/MditsLogo/mdits.png'

const Navbar = () => {
    return (
        <div className="top">
            <div className='title'>
                <Link className='link' to="/">
                    <img className='mdits-title' src={MDITS} height={30} width={85} alt="" />
                    <p className='mdits-title-name'>Mission Driven It Solutions Pvt.Ltd</p>
                </Link>
            </div>

            <span className='navbar-item-spacer'></span>
            <div className="topCenter">
                <ul className="topList">
                    <li className="topListItem">
                        <Link className="link" to="/about">
                            <h6>ABOUT</h6>
                        </Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/services">
                            <h6>SERVICES</h6>
                        </Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to='/portfolio'>
                            <h6>PORTFOLIO</h6>
                        </Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/team">
                            <h6>TEAM</h6>
                        </Link>
                    </li>
                    <li className="topListItem">
                        <Link className="link" to="/contact">
                            <h6>CONTACT</h6>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Navbar;