import './Home.css'
import Hari from '../../assets/CardImages/hari.png'
import Venkat from '../../assets/CardImages/venkat.png'
import Vamsi from '../../assets/CardImages/vamsi.png'
import Hussain from '../../assets/CardImages/hussain.png'
import Team from '../../assets/HomeImages/team.png'
import OnTime from '../../assets/HomeImages/OnTime.png'
import Completed from '../../assets/HomeImages/Completed.png'
import OnBudget from '../../assets/HomeImages/OnBudget.png'
import Slider from './ImageSlider/Slider'
const Home = () => {

    return (

        <div className='home'>
            <Slider />
            <div className='success-container'>
                <img className='team-image' src={Team} alt="" />
                <div className='team-text'>
                    <h1>
                        We have lot's of<br />
                        Success Stories
                    </h1>
                    <br />
                    <div>
                        <img className='team-images' src={Completed} alt="" />
                        <div>
                            <h5 className='team-images-text'>Completed</h5>
                            <h6>100%</h6>
                        </div>
                    </div>
                    <div>
                        <img className='team-images' src={OnBudget} alt="" />
                        <div>
                            <h5 className='team-images-text'>OnBudget</h5>
                            <h6>100%</h6>
                        </div>
                    </div>
                    <div>
                        <img className='team-images' src={OnTime} alt="" />
                        <div>
                            <h5 className='team-images-text'>OnTime</h5>
                            <h6>100%</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className='cards-header'>
                <h1>Client Reviews</h1>
                <br />
                <div className='cards'>
                    <div className="card-container">
                        <h1 className='name'>Venkat</h1>
                        <h5 className='name'>Delhi</h5>
                        <br />
                        <p>Ajay is a great developer, very
                            experienced and able to get the job done
                            quickly! I am hiring him again for my next
                            project.
                        </p>
                        <header>
                            <img className='card-image' src={Venkat} alt="" />
                        </header>
                    </div>
                    <div className="card-container">
                        <h1 className='name'>Akhil</h1>
                        <h5 className='name'>Bangalore</h5>
                        <br />
                        <p>He was always available for
                            communication when needed and did a
                            really good job. I will work with him in the
                            future.
                        </p>
                        <header>
                            <img className='card-image' src={Hari} alt="" />
                        </header>
                    </div>

                    <div className="card-container">
                        <h1 className='name'>Charan</h1>
                        <h5 className='name'>Mumabi</h5>
                        <br />
                        <p>
                            Ketan is good skill person with good verbal
                            and written English.He's review our app in
                            short time and we defined our tasks.
                        </p>
                        <header>
                            <img className='card-image' src={Vamsi} alt="" />
                        </header>
                    </div>
                    <div className="card-container">
                        <h1 className='name'>Vijay</h1>
                        <h5 className='name'>Hyderabad</h5>
                        <br />
                        <p>
                            Shiva is good skill person with good verbal
                            and written English.He's review our app in
                            short time and we defined our tasks.
                        </p>
                        <header>
                            <img className='card-image' src={Hussain} alt="" />
                        </header>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
        </div>
    )
}
export default Home;