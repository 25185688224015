import './Footer.css'

const Footer = () => {
    return (
        <div className="footer">
            <p>Copyright@2023Mdits</p>
            <p>mdits.co.in</p>
        </div>
    )
}
export default Footer;